import { React } from 'react'
import { withRouter} from 'react-router-dom';

import { Navbar, Nav, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";


const Home = ({history}) => {
	const name = "Toshi Tanaka";
	const tagline = "London. Tokyo. World.";

	const importAll = (r) => {
		return r.keys().map(r);
	}
	

	const loadImages = () => {
		return importAll(require.context('../images/gallery', false, /\.(png|jpe?g|svg)$/));
	}

	const images = loadImages();

	const navbar = () => {
		return (
		<Navbar bg="light" expand="lg" id="page-navbar">
		  <Navbar.Brand href="#home" id="brand">{ name }</Navbar.Brand>
		  <Navbar.Toggle aria-controls="basic-navbar-nav" />
		  <Navbar.Collapse id="basic-navbar-nav">
			<Nav className="ml-auto">
			  <Nav.Link href="#home">Home</Nav.Link>
			</Nav>
		  </Navbar.Collapse>
		</Navbar>
		)
	}

	const sectionTagline = () => {
		return (
			<section id="tagline-banner">
			  <div className="container">
				<div className="row">
					<div className="block" style={{textAlign:"center",width:"100%"}}>
					  <h1 className="animated fadeInUp">{ tagline }</h1>
					</div>
				</div>
			  </div>
			</section>
		)
	}


	const sectionGallery = () => {
		return (
			<section id="about">
				<div className="container">
					<div className="section-title"><h2>Photo Gallery</h2></div>
					<div className="block">
						<Carousel infiniteLoop autoPlay showThumbs={false} interval={5000}>
						{ images.map((image) => {
								return <div><img src={ image.default } alt={image.default}/></div>
							})
						}
						</Carousel>
					</div>
				</div>
			</section>
		)
	}

	const sectionContact = () => {
		return (
			<section id="contact">
			  <div className="container">
					<div className="section-title">
					  <h2>Contact</h2>
					</div>
					<div className="block">
						<Button variant="light"><a href="mailto:abc@efg.com">Contact Me</a></Button>

					</div>
			  </div>

			</section>
		)
	}

	const footer = () => {
		return (
			<footer>
			  <div className="container">
				<div className="row">
				  <div className="col-md-12">
					<div className="footer-menu">
						<ul>
     						<li>
									<FontAwesomeIcon icon={faEnvelope} fixedWidth/></li>
 							<li><a href="http://www.linkedin.com" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faLinkedin} fixedWidth/></a></li>
						</ul>
					</div>
				  </div>
				</div>
			  </div>
			</footer>
		)
	}

	return (<>
		{ navbar() }
		{ sectionTagline() }
		{ sectionGallery() }
		<br/><br/>
		{ sectionContact() }
		{ footer() }
	</>
	)
}

export default withRouter(Home);
