  
import React from 'react';

import { Route, Switch, BrowserRouter as Router} from 'react-router-dom';
// import the components required below
import { Home, NotFound } from '.';

const Routing = (
    <Router>
        <div>
            <main>
            <Switch>
                <Route exact path="/"       component={Home}/>
                <Route component={NotFound}/>
            </Switch>
            </main>
        </div>
    </Router>
);

export default Routing;
