import ReactDOM from 'react-dom';

import './css/core.css'
import './css/animate.css'
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'font-awesome/css/font-awesome.min.css';


import Routing from './routes/routing';
import reportWebVitals from './reportWebVitals';

// rendering the App component in the id="root" element in index.html
ReactDOM.render(Routing, document.querySelector('#root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
